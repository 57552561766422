import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from 'styled-components'
import { Theme } from 'utils/theme'
import { createBrowserRouter, RouterProvider, Routes, Route, Navigate } from 'react-router-dom'
import PersonalDetailsStep from 'form/PersonalDetailsStep'
import AdditionalCategoriesStep from 'form/AdditionalCategoriesStep'
import SummaryStep from 'form/SummaryStep'
import Farewell from 'form/Farewell'
import { AppCenterContextProvider } from 'AppContext'
import 'utils/global.css'
import { Toaster } from 'react-hot-toast'
import Cookies from 'components/Cookies'
import Login from 'admin/pages/Login'
import Entries from 'admin/pages/Entries'
import Candidates from 'admin/pages/Candidates'
import AddSuccessCandidate from 'admin/pages/AddSuccessCandidate'
import EditSuccessCandidate from 'admin/pages/EditSuccessCandidate'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'
import Booklets from 'admin/pages/Booklets'
import AddInnovationCandidate from 'admin/pages/AddInnovationCandidate'
import EditInnovationCandidate from 'admin/pages/EditInnovationCandidate'
import AddCharityCandidate from 'admin/pages/AddCharityCandidate'
import EditCharityCandidate from 'admin/pages/EditCharityCandidate'
import SingleCandidatePreview from 'admin/pages/SingleCandidatePreview'
import BookletPreview from 'admin/components/BookletPreview'
import Voters from 'admin/pages/Voters'
import Voting from 'admin/pages/Voting'
import NomineePreview from 'admin/pages/NomineePreview'
import InitialVoting from 'admin/pages/InitialVoting'
import InitialVotingResults from 'admin/pages/InitialVotingResults'
import FinalVotingResults from 'admin/pages/FinalVotingResults'
import FinalistsBookletPreview from 'admin/components/FinalistsBookletPreview'
import ResultsPresentation from 'admin/pages/ResultsPresentation'
import VotersLinks from 'admin/pages/VotersLinks'
import Invitations from 'admin/pages/Invitations'
import InvitationResponse from 'pages/InvitationResponse'

const PublicRoute = ({ children }: { children: React.ReactNode }) => {
  const path = window.location.pathname;
  // More permissive regex that allows for longer/shorter codes
  const linkRegex = /^\/zaproszenie\/[a-z0-9]{8,50}\/?$/;
  
  // Debug log to see what path we're getting
  console.log('Current path:', path);
  
  // Check for invitation path without the identifier
  if (path === '/zaproszenie' || path === '/zaproszenie/') {
    return <div style={{padding: '20px', textAlign: 'center'}}>
      <h2>Nieprawidłowy link do zaproszenia</h2>
      <p>Link do zaproszenia jest niepełny. Prosimy o sprawdzenie pełnego adresu URL lub skontaktowanie się z organizatorem.</p>
    </div>;
  }
  
  // If it starts with /zaproszenie/ but doesn't match our regex, it's invalid
  if (path.startsWith('/zaproszenie/') && !linkRegex.test(path)) {
    console.log('Invalid invitation link format');
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const router = createBrowserRouter([
  {
    path: '/',
    element: <PersonalDetailsStep />
  },
  {
    path: '/formularze',
    element: <AdditionalCategoriesStep />
  },
  {
    path: '/podsumowanie',
    element: <SummaryStep />
  },
  {
    path: '/podziekowanie',
    element: <Farewell />
  },
  {
    path: '/admin',
    element: <Login />
  },
  {
    path: '/admin/zgloszenia',
    element: <Entries />
  },
  {
    path: '/admin/kandydaci',
    element: <Candidates />
  },
  {
    path: '/admin/kandydaci/sukces/dodaj',
    element: <AddSuccessCandidate />
  },
  {
    path: '/admin/kandydaci/zobacz/:id',
    element: <SingleCandidatePreview />
  },
  {
    path: '/admin/kandydaci/sukces/edytuj/:id',
    element: <EditSuccessCandidate />
  },
  {
    path: '/admin/kandydaci/innowacje/dodaj',
    element: <AddInnovationCandidate />
  },
  {
    path: '/admin/kandydaci/innowacje/edytuj/:id',
    element: <EditInnovationCandidate />
  },
  {
    path: '/admin/kandydaci/dzialalnosc-spoleczna/dodaj',
    element: <AddCharityCandidate />
  },
  {
    path: '/admin/kandydaci/dzialalnosc-spoleczna/edytuj/:id',
    element: <EditCharityCandidate />
  },
  {
    path: '/admin/broszury',
    element: <Booklets />
  },
  {
    path: '/broszura/:type',
    element: <BookletPreview />
  },
  {
    path: '/finalisci/:type',
    element: <FinalistsBookletPreview />
  },
  {
    path: '/admin/glosujacy',
    element: <Voters />
  },
  {
    path: '/kapitula/:code/:id',
    element: <NomineePreview />
  },
  {
    path: '/preselekcja/:code',
    element: <InitialVoting />
  },
  {
    path: '/preselekcja/:code/:id',
    element: <NomineePreview />
  },
  {
    path: '/kapitula/:code',
    element: <Voting />
  },
  {
    path: '/admin/preselekcja/',
    element: <InitialVotingResults />
  },
  {
    path: '/admin/finalisci/',
    element: <FinalVotingResults />
  },
  {
    path: '/admin/prezentacja-wynikow',
    element: <ResultsPresentation />
  },
  {
    path: '/admin/linki',
    element: <VotersLinks />
  },
  {
    path: '/admin/zaproszenia',
    element: <Invitations />
  },
  {
    path: '/zaproszenie/:link',
    element: <PublicRoute><InvitationResponse /></PublicRoute>
  }
])

root.render(
  <ThemeProvider theme={Theme}>
    <Toaster />
    <AppCenterContextProvider>
      <RouterProvider router={router} />
      <div id='modal-portal' />
      <Cookies />
    </AppCenterContextProvider>
  </ThemeProvider>
)
