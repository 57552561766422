import React, { useContext } from 'react'
import CategorySection from 'components/CategorySection'
import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import styled from 'styled-components'
import { SectionDataTypes } from 'utils/sectionDataTypes'
import AppContext, { AppContextTypes } from 'AppContext'
import { mobileCss } from 'utils/theme'

interface FormTemplateTypes {
  sectionData: SectionDataTypes,
}

const FormTemplate = ({ sectionData }: FormTemplateTypes) => {
  const { values, formValueChange } = useContext(AppContext) as AppContextTypes

  const handleInputChange = (path: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    formValueChange(path, e.target.value)
  }

  const { formType } = sectionData

  return (
    <>
      <CategorySection {...sectionData} />
      <Column>
        <Row>
          <TextField
            fullWidth
            value={values[formType].firstName || ''}
            onChange={handleInputChange(`${formType}.firstName`)}
            label='Imię'
            variant='outlined'
          />
          <TextField
            fullWidth
            value={values[formType].lastName || ''}
            onChange={handleInputChange(`${formType}.lastName`)}
            label='Nazwisko'
            variant='outlined'
          />
        </Row>
        <Row>
          <TextField
            fullWidth
            value={values[formType].email || ''}
            onChange={handleInputChange(`${formType}.email`)}
            label='Adres email'
            variant='outlined'
          />
          <TextField
            fullWidth
            value={values[formType].phoneNumber || ''}
            onChange={handleInputChange(`${formType}.phoneNumber`)}
            label='Numer telefonu'
            variant='outlined'
          />
        </Row>
        <Row>
          <TextField
            fullWidth
            value={values[formType].companyName || ''}
            onChange={handleInputChange(`${formType}.companyName`)}
            label='Nazwa firmy/organizacji'
            variant='outlined'
          />
          <TextField
            fullWidth
            value={values[formType].industry || ''}
            onChange={handleInputChange(`${formType}.industry`)}
            label='Nazwa branży, w której działa osoba nominowana'
            variant='outlined'
          />
        </Row>
        <FormControlLabel
          control={
            <Checkbox
              checked={Boolean(values[formType].isPublicCompany)}
              onChange={(e) => {
                formValueChange(`${formType}.isPublicCompany`, e.target.checked);
              }}
            />
          }
          label='Czy firma jest spółką publiczną: Zaznacz, jeśli TAK'
        />
        <Row>
          <TextField
            fullWidth
            value={values[formType].website || ''}
            onChange={handleInputChange(`${formType}.website`)}
            label='Adres WWW firmy / organizacji'
            variant='outlined'
          />
          <TextField
            fullWidth
            value={values[formType].position || ''}
            onChange={handleInputChange(`${formType}.position`)}
            label='Stanowisko w firmie / organizacji'
            variant='outlined'
          />
        </Row>
        <p>To najważniejszy element nominacji. Uzasadnij, dlaczego nominowana przez Ciebie osoba powinna otrzymać
          Nagrodę
          Polskiej Rady Biznesu w kategorii WIZJA I INNOWACJE. Opisz osiągnięcia osoby nominowanej, innowacyjny
          charakter
          jej projektu/firmy, zdobyte wyróżnienia lub nagrody. W nagłówku przypominamy kryteria, którymi kieruje się
          Kapituła Nagrody.</p>
        <TextField
          fullWidth
          multiline
          rows={4}
          value={values[formType].justification || ''}
          onChange={handleInputChange(`${formType}.justification`)}
          label='Treść uzasadnienia...'
          variant='outlined'
        />
      </Column>
    </>
  )
}

export default FormTemplate

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
const Row = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;

  ${mobileCss(`
    flex-direction: column;
  `)}
`