import React, { useEffect, useState } from 'react'
import { supabase } from 'utils/supabase'
import { VoterTypes } from 'admin/pages/Voters'
import VotersLinksTable from 'admin/components/VotersLinksTable'
import styled from 'styled-components'

const VotersLinks = () => {
  const [voters, setVoters] = useState<VoterTypes[] | []>([])

  const getVoters = async () => {
    try {
      const response = await supabase
        .from('voters')
        .select()
        .order('name')

      const data = response.data as VoterTypes[]
      setVoters(data)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getVoters()
  }, [])

  return (
    <Wrapper>
      <Title>Tabela linków</Title>
      {voters && <VotersLinksTable voters={voters} />}
    </Wrapper>
  )
}

export default VotersLinks

const Wrapper = styled.div`
  padding: 20px;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 30px;
` 