import React from 'react'
import styled from 'styled-components'
import { VoterTypes } from 'admin/pages/Voters'
import { mobileCss } from 'utils/theme'

interface VotersLinksTableProps {
  voters: VoterTypes[]
}

const VotersLinksTable = ({ voters }: VotersLinksTableProps) => {
  const baseUrl = 'https://prbnagroda.pl'

  return (
    <TableWrapper>
      <Table>
        <thead>
          <tr>
            <th>Imię i nazwisko</th>
            <th>Link do preselekcji</th>
            <th>Link do głosowania finałowego</th>
          </tr>
        </thead>
        <tbody>
          {voters.map(voter => (
            <tr key={voter.id}>
              <td>{voter.name}</td>
              <td>
                {`${baseUrl}/preselekcja/${voter.code}`}
              </td>
              <td>
                {`${baseUrl}/kapitula/${voter.code}`}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  )
}

export default VotersLinksTable

const TableWrapper = styled.div`
  overflow-x: auto;
  margin: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  font-size: 14px;
  background: white;

  th {
    padding: 12px 16px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    font-weight: 600;
    text-align: left;
  }

  td {
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
    font-family: monospace;
    font-size: 13px;
  }

  ${mobileCss(`
    th, td {
      padding: 8px;
    }
  `)}
` 