import React from 'react'
import { Tooltip } from '@mui/material'
import { IconEye } from '@tabler/icons-react'
import styled from 'styled-components'
import { isNotNilOrEmpty } from 'utils/ramda'
import { VoterTypes } from 'admin/pages/Voters'
import RemoveVoterModal from 'admin/components/RemoveVoterModal'
import VotingLinkModal from 'admin/components/VotingLinkModal'

interface VotersTableProps {
  voters: VoterTypes[]
  onRemove: () => void
}

const VotersTable = ({ voters, onRemove }: VotersTableProps) => {
  return isNotNilOrEmpty(voters) ? (
    <Table>
      <thead>
      <tr>
        <th>Nazwa</th>
        <th>email</th>
        <th />
      </tr>
      </thead>
      <tbody>
      {voters.map(voter => (
        <TableRow key={voter.id}>
          <td>{voter?.name || '---'}</td>
          <td>{voter?.email || '---'}</td>
          <td>
            <Actions>
              <VotingLinkModal voter={voter} />
              <RemoveVoterModal id={voter.id} onRemove={onRemove} />
            </Actions>
          </td>
        </TableRow>
      ))}
      </tbody>
    </Table>
  ) : (
    <div>Brak osób głosujących</div>
  )
}

export default VotersTable

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  font-size: 14px;
  
  th {
    text-align: left;
    padding: 16px;
    background-color: #f2f2f2;
  }
  
  td {
    padding: 8px 16px;
    border-bottom: #ccc;
    border-bottom: 1px solid #f2f2f2;
  }
`

const TableRow = styled.tr`
  transition: all 0.2s ease;
  
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
    
    td {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`

const IconWrapper = styled.div`
  cursor: pointer;
`
