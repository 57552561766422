import emailjs from '@emailjs/browser';

// Add version check
console.log('Email service version: 2024-03-24-4');

// Initialize EmailJS with your public key from environment variable
emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY!);

interface EmailData {
  nameA: string;
  lastNameA: string;
  firstNameB?: string;
  lastNameB?: string;
  emails: string[];
  individualLink: string;
  addressNames: string;
}

const formatAddressNames = (data: EmailData): string => {
  const nameA = `${data.nameA} ${data.lastNameA}`;
  if (data.firstNameB && data.lastNameB) {
    return `${nameA}, ${data.firstNameB} ${data.lastNameB}`;
  }
  return nameA;
};

const formatLink = (link: string): string => {
  // If the link already has a protocol, return it as is
  if (link.startsWith('http://') || link.startsWith('https://')) {
    return link;
  }
  
  // Otherwise, construct the full URL
  const baseUrl = process.env.REACT_APP_URL?.replace(/\/$/, '') || '';
  const normalizedLink = link.startsWith('/') ? link : `/${link}`;
  return `${baseUrl}${normalizedLink}`;
};

export const sendInvitation = async ({
  nameA,
  lastNameA,
  firstNameB,
  lastNameB,
  emails,
  individualLink,
  addressNames
}: EmailData) => {
  try {
    if (!emails || emails.length === 0) {
      console.error('No email addresses provided');
      return false;
    }

    // Format the link properly
    const formattedLink = formatLink(individualLink);
    console.log('Formatted link:', formattedLink);

    // Send to all email addresses in the list
    const sendPromises = emails.map(email => {
      const templateParams = {
        email: email,
        email_list: emails.join(','), // Keep all recipients in the list
        address_names: addressNames,
        name_a: nameA,
        last_name_a: lastNameA,
        name_b: firstNameB || '',
        last_name_b: lastNameB || '',
        individualLink: formattedLink
      };

      console.log('Sending email with params:', templateParams);

      return emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID!,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID!,
        templateParams
      );
    });

    // Wait for all emails to be sent
    const results = await Promise.all(sendPromises);
    return results.every(response => response.status === 200);
  } catch (err) {
    console.error('Error sending invitation:', err);
    return false;
  }
}; 