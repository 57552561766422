import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { supabase } from 'utils/supabase'
import { mobileCss } from 'utils/theme'
import { Modal, Button, TextField, IconButton, LinearProgress } from '@mui/material'
import { toast } from 'react-toastify'
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material'
import { ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import 'react-toastify/dist/ReactToastify.css'
import { sendInvitation } from 'services/email'

type Decision = 'Tak' | 'Nie' | 'all' | null

type SendingDates = string[]

const shouldFollowUp = (sendingDates: SendingDates | null): boolean => {
  if (!sendingDates || sendingDates.length === 0) {
    return true
  }

  const dates = sendingDates.map(date => new Date(date))
  const mostRecentDate = new Date(Math.max(...dates.map(date => date.getTime())))
  const sevenDaysAgo = new Date()
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)

  return mostRecentDate < sevenDaysAgo
}

const ADDITIONAL_GUESTS_OPTIONS = [-1, 0, 1] as const
type AdditionalGuestsType = typeof ADDITIONAL_GUESTS_OPTIONS[number]

const GROUP_MAPPING = {
  kapitula: 'Kapituła',
  finalists: 'Finaliści',
  czlonkowiePRB: 'Członkowie PRB',
  BNGuest: 'BN Guest',
  PRBGuest: 'PRB Guest',
  vip: 'VIP',
  przewodniczacyK: 'Przewodniczący Kapituły',
  pwc: 'Partner Merytoryczny',
  rodzinaCz: 'Rodzina Czerneckich',
  rodzinaK: 'Rodzina Kulczyk',
  rodzinaW: 'Rodzina Wejchert',
  other: 'Inna'
} as const

type GroupKey = keyof typeof GROUP_MAPPING

interface InvitationType {
  id: string
  firstNameA: string
  lastNameA: string
  firstNameB: string | null
  lastNameB: string | null
  emailA: string
  emailB: string | null
  emailC: string | null
  emailD: string | null
  emailE: string | null
  decision: Decision
  individualLink: string
  replied: boolean
  paperInvitation: boolean
  sendingDates: SendingDates
  group: GroupKey
  inviteesCount: string
  additionalGuests: string
  totalGuests: string
  followUpNeeded: boolean
  addressNames?: string
}

interface FilterState {
  decision: Decision
  replied: 'all' | 'true' | 'false'
  paperInvitation: 'all' | 'true' | 'false'
  followUpNeeded: 'all' | 'true' | 'false'
  group: string
}

type SortField = 'lastNameA' | 'group' | 'decision' | 'followUpNeeded' | 'replied'
type SortDirection = 'asc' | 'desc'

const SearchBar = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #f2f2f2;
  
  .search-count {
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    white-space: nowrap;
    margin-left: auto;
  }
`

const SearchInput = styled.input`
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 350px;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
  }
`

const SearchButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.colors.text};
    border-color: ${({ theme }) => theme.colors.border};
    min-width: 100px;
    
    &:hover {
      background-color: ${({ theme }) => theme.colors.background};
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }
`

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  background: white;
  z-index: 1000;
  border-bottom: 1px solid #f2f2f2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin: -20px -20px 0 -20px;
  padding: 20px;
`

const FiltersArea = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-items: flex-end;
`

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const FilterLabel = styled.label`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text};
`

const FilterSelect = styled.select`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
`

const ActionsArea = styled.div`
  display: flex;
  gap: 16px;
  margin-left: auto;
`

const FiltersSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 16px;
`

const AddButton = styled(Button)`
  && {
    margin-left: auto;
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary}dd;
    }
  }
`

const EmailsCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 200px;
  
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const DatesCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 120px;
  margin: 0 auto;
  
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
`

const Summary = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  gap: 24px;
`

const SummaryTable = styled.table`
  border-collapse: collapse;
  min-width: 100px;
  
  th, td {
    padding: 8px 8px;
    border: 2px solid #eee;
    font-size: 14px;
    text-align: center;
  }
  
  th {
    background-color: #f8f8f8;
    font-weight: 500;
    text-align: center;
  }

  td {
    vertical-align: middle;
  }
`

const SummaryCell = styled.td<{ type: 'tak' | 'nie' }>`
  background-color: ${({ type, theme }) => 
    type === 'tak' ? `${theme.colors.success}22` : `${theme.colors.error}22`
  };
  color: ${({ type, theme }) => 
    type === 'tak' ? theme.colors.success : theme.colors.error
  };
  font-weight: 500;
  gap: 4px;
  min-width: 40px;

  .percentage {
    font-size: 12px;
    opacity: 0.8;
  }
`

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`

const BackButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.colors.text};
    border-color: ${({ theme }) => theme.colors.border};
    
    &:hover {
      background-color: ${({ theme }) => theme.colors.background};
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }
`

const ClearButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.colors.text};
    border-color: ${({ theme }) => theme.colors.border};
    
    &:hover {
      background-color: ${({ theme }) => theme.colors.background};
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }
`

const DeleteButton = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.colors.error};
    color: white;
    
    &:hover {
      background-color: ${({ theme }) => theme.colors.error}dd;
    }
  }
`

const ActionsCell = styled.div`
  display: flex;
  gap: 8px;
`

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 32px;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;

  h2 {
    margin-top: 0;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 24px;
  }
`

const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 24px;
`

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
`

const ActionButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.colors.text};
    border-color: ${({ theme }) => theme.colors.border};
    
    &:hover {
      background-color: ${({ theme }) => theme.colors.background};
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }
`

const TableWrapper = styled.div`
  flex: 1;
  overflow: auto;
  margin-top: 20px;
  position: relative;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }

  th {
    background-color: #f8f8f8;
    font-weight: 500;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  tbody tr:hover {
    background-color: #f5f5f5;
  }

  .highlight {
    background-color: ${({ theme }) => theme.colors.primary}22;
  }
`

const TableRow = styled.tr`
  &:hover {
    background-color: #f5f5f5;
  }
`

const SortableHeader = styled.th`
  cursor: pointer;
  user-select: none;
  
  &:hover {
    background-color: #f0f0f0;
  }

  .sort-icon {
    margin-left: 4px;
    opacity: 0.5;
  }
`

const ColumnTotal = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text}99;
  margin-top: 4px;
`

const DecisionCell = styled.td<{ decision: Decision | null }>`
  color: ${({ decision, theme }) => {
    switch (decision) {
      case 'Tak':
        return theme.colors.success;
      case 'Nie':
        return theme.colors.error;
      default:
        return theme.colors.text;
    }
  }};
`
const FormSelect = styled.select`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 100%;
`

const FormCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  grid-column: 1 / -1;
`

const ActionsToolbar = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`

const ProgressWrapper = styled.div`
  margin: 20px 0;
  width: 100%;
`

const ProgressText = styled.div`
  text-align: center;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.text};
`

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
`

const Invitations = () => {
  const navigate = useNavigate()
  const [invitations, setInvitations] = useState<InvitationType[]>([])
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [editingInvitation, setEditingInvitation] = useState<InvitationType | null>(null)
  const [sortField, setSortField] = useState<SortField>('lastNameA')
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc')
  const [filters, setFilters] = useState<FilterState>({
    decision: 'all',
    replied: 'all',
    paperInvitation: 'all',
    followUpNeeded: 'all',
    group: ''
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0)
  const [matchedRows, setMatchedRows] = useState<number[]>([])
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false)
  const [deleteModalInvitation, setDeleteModalInvitation] = useState<InvitationType | { count: number } | null>(null)
  const [isSending, setIsSending] = useState(false)
  const [sendingProgress, setSendingProgress] = useState(0)
  const [isSendModalOpen, setIsSendModalOpen] = useState(false)
  const [isGenerateLinksModalOpen, setIsGenerateLinksModalOpen] = useState(false)
  const [isDeletingLinks, setIsDeletingLinks] = useState(false)
  const [deleteLinksProgress, setDeleteLinksProgress] = useState(0)
  const [generateLinksProgress, setGenerateLinksProgress] = useState(0)
  const [isDeleteLinksModalOpen, setIsDeleteLinksModalOpen] = useState(false)

  const PRODUCTION_URL = 'https://nagroda.prb.pl'

  useEffect(() => {
    fetchInvitations()
  }, [])

  const fetchInvitations = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession()
      const { data, error } = await supabase
        .from('invitations')
        .select('*')
        .throwOnError()
      
      if (error) {
        console.error('Error fetching invitations:', error)
        toast.error('Error fetching invitations: ' + error.message)
        return
      }
      
      if (data) {
        const processedData = data.map(invitation => {
          // Ensure sendingDates is always an array
          let sendingDates: string[] = []
          try {
            if (invitation.sendingDates) {
              // Handle both array and object formats for backward compatibility
              if (Array.isArray(invitation.sendingDates)) {
                // Sort dates in descending order and take only the latest 3
                sendingDates = [...invitation.sendingDates]
                  .filter((date): date is string => typeof date === 'string')
                  .sort((a, b) => 
                    new Date(b).getTime() - new Date(a).getTime()
                  )
                  .slice(0, 3)
              } else if (typeof invitation.sendingDates === 'object') {
                // Convert old object format to array, sort, and take latest 3
                sendingDates = Object.values(invitation.sendingDates)
                  .filter((date): date is string => typeof date === 'string')
                  .sort((a, b) => 
                    new Date(b).getTime() - new Date(a).getTime()
                  )
                  .slice(0, 3)
              }
            }
          } catch (err) {
            console.error('Error processing sendingDates for invitation:', invitation.id, err)
            sendingDates = []
          }

          return {
            ...invitation,
            paperInvitation: invitation.paperInvitation ?? true,
            followUpNeeded: shouldFollowUp(sendingDates),
            inviteesCount: invitation.inviteesCount || calculateInviteesCount(invitation).toString(),
            additionalGuests: invitation.additionalGuests || '0',
            totalGuests: invitation.totalGuests || calculateTotalGuests(
              calculateInviteesCount(invitation),
              invitation.additionalGuests || '0'
            ).toString(),
            sendingDates
          }
        })
        setInvitations(processedData)
      }
    } catch (err) {
      console.error('Unexpected error while fetching invitations:', err)
      toast.error('Wystąpił nieoczekiwany błąd podczas pobierania zaproszeń')
    }
  }

  const getFilteredInvitations = () => {
    return invitations.filter((inv: InvitationType) => {
      const matchesDecision = filters.decision === 'all' || inv.decision === filters.decision
      const matchesReplied = filters.replied === 'all' || inv.replied === (filters.replied === 'true')
      const matchesPaper = filters.paperInvitation === 'all' || inv.paperInvitation === (filters.paperInvitation === 'true')
      const matchesFollowUp = filters.followUpNeeded === 'all' || inv.followUpNeeded === (filters.followUpNeeded === 'true')
      const matchesGroup = !filters.group || inv.group.includes(filters.group)

      return matchesDecision && matchesReplied && matchesPaper && matchesFollowUp && matchesGroup
    })
  }

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedIds(getFilteredInvitations().map((inv: InvitationType) => inv.id))
    } else {
      setSelectedIds([])
    }
  }

  const handleSelectOne = (id: string) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId: string) => selectedId !== id))
    } else {
      setSelectedIds([...selectedIds, id])
    }
  }

  const calculateInviteesCount = (invitation: Partial<InvitationType>): number => {
    // Groups that always get 2 invitees
    if (invitation.group === 'kapitula' || invitation.group === 'finalists' || invitation.group === 'czlonkowiePRB') {
      return 2
    }
    // For all other groups, count is based on whether there's a second person
    return invitation.firstNameB ? 2 : 1
  }

  const calculateTotalGuests = (inviteesCount: number, additionalGuests: string): number => {
    return inviteesCount + (parseInt(additionalGuests) || 0)
  }

  const formatAddressNames = (invitation: Partial<InvitationType>): string => {
    const firstNameA = invitation.firstNameA?.trim() || '';
    const lastNameA = invitation.lastNameA?.trim() || '';
    const firstNameB = invitation.firstNameB?.trim() || '';
    const lastNameB = invitation.lastNameB?.trim() || '';
    
    const fullNameA = `${firstNameA} ${lastNameA}`.trim();
    const fullNameB = `${firstNameB} ${lastNameB}`.trim();
    
    if (!fullNameA) return '';
    // Only include 'oraz' and second name if both firstName AND lastName of person B exist
    if (!firstNameB || !lastNameB) return fullNameA;
    
    return `${fullNameA} oraz ${fullNameB}`;
  };

  const handleGenerateLinks = async () => {
    if (!isGenerateLinksModalOpen) {
      setIsGenerateLinksModalOpen(true);
      return;
    }

    try {
      const progressToast = toast.loading('Generowanie linków...');
      let successCount = 0;
      let failCount = 0;

      const invitationsNeedingLinks = invitations.filter(inv => !inv.individualLink);

      for (const [index, invitation] of invitationsNeedingLinks.entries()) {
        try {
          const uniqueId = Math.random().toString(36).substring(2, 10);
          const fullUrl = `https://prbnagroda.pl/zaproszenie/${uniqueId}`;

          const { error } = await supabase
            .from('invitations')
            .update({ individualLink: fullUrl })
            .eq('id', invitation.id);

          if (error) {
            console.error('Error generating link:', error);
            failCount++;
          } else {
            successCount++;
          }

          const progress = Math.round(((index + 1) / invitationsNeedingLinks.length) * 100);
          setGenerateLinksProgress(progress);
          toast.update(progressToast, {
            render: `Generowanie linków: ${index + 1}/${invitationsNeedingLinks.length}`
          });
        } catch (err) {
          console.error('Error processing invitation:', err);
          failCount++;
        }
      }

      toast.dismiss(progressToast);
      if (successCount > 0) {
        toast.success(`Wygenerowano ${successCount} linków`);
      }
      if (failCount > 0) {
        toast.error(`Nie udało się wygenerować ${failCount} linków`);
      }

      setGenerateLinksProgress(0);
      await fetchInvitations();
    } catch (err) {
      console.error('Error generating links:', err);
      toast.error('Wystąpił błąd podczas generowania linków');
      setGenerateLinksProgress(0);
    } finally {
      setIsGenerateLinksModalOpen(false);
    }
  };

  const handleUpdateAddressNames = async () => {
    try {
      const progressToast = toast.loading('Aktualizacja zwrotów adresowych...');
      let successCount = 0;
      let failCount = 0;

      for (const [index, invitation] of invitations.entries()) {
        try {
          const updates = {
            addressNames: formatAddressNames(invitation)
          };

          const { error } = await supabase
            .from('invitations')
            .update(updates)
            .eq('id', invitation.id);

          if (error) {
            console.error('Error updating address names:', error);
            failCount++;
          } else {
            successCount++;
          }

          toast.update(progressToast, {
            render: `Aktualizacja: ${index + 1}/${invitations.length}`
          });
        } catch (err) {
          console.error('Error processing invitation:', err);
          failCount++;
        }
      }

      toast.dismiss(progressToast);
      if (successCount > 0) {
        toast.success(`Zaktualizowano ${successCount} zwrotów adresowych`);
      }
      if (failCount > 0) {
        toast.error(`Nie udało się zaktualizować ${failCount} zwrotów adresowych`);
      }

      await fetchInvitations();
    } catch (err) {
      console.error('Error updating address names:', err);
      toast.error('Wystąpił błąd podczas aktualizacji zwrotów adresowych');
    }
  };

  const handleAddInvitation = async (newInvitation: Partial<InvitationType>) => {
    try {
      const inviteesCount = calculateInviteesCount(newInvitation);
      // Generate a random string of 8-50 alphanumeric characters
      const uniqueId = Math.random().toString(36).substring(2, 10);
      const individualLink = `https://prbnagroda.pl/zaproszenie/${uniqueId}`;
      const addressNames = formatAddressNames(newInvitation);
      
      const { data, error } = await supabase
        .from('invitations')
        .insert([{
          id: uuidv4(),
          ...newInvitation,
          individualLink,
          addressNames,
          paperInvitation: newInvitation.paperInvitation ?? true,
          sendingDates: [],
          inviteesCount: inviteesCount.toString(),
          additionalGuests: '0',
          totalGuests: inviteesCount.toString()
        }]);
      
      if (error) {
        console.error('Error adding invitation:', error);
        toast.error('Nie udało się dodać osoby. ' + error.message);
        return;
      }
      
      toast.success('Pomyślnie dodano osobę');
      fetchInvitations();
      setIsAddModalOpen(false);
    } catch (err) {
      console.error('Exception while adding invitation:', err);
      toast.error('Wystąpił nieoczekiwany błąd podczas dodawania osoby');
    }
  };

  const handleEditInvitation = async (updatedInvitation: Partial<InvitationType>) => {
    if (!editingInvitation?.id) return

    try {
      let inviteesCount = calculateInviteesCount(updatedInvitation)
      let additionalGuests = updatedInvitation.additionalGuests || '0'
      let totalGuests = '0'
      
      // If decision is "Nie", set all guest counts to 0
      if (updatedInvitation.decision === 'Nie') {
        inviteesCount = 0
        additionalGuests = '0'
        totalGuests = '0'
      } else {
        totalGuests = calculateTotalGuests(inviteesCount, additionalGuests).toString()
      }

      // Decision logic
      const hasDecision = !!updatedInvitation.decision
      const replied = hasDecision
      const followUpNeeded = !hasDecision && shouldFollowUp(updatedInvitation.sendingDates || [])
      
      // Update addressNames based on current names
      const addressNames = formatAddressNames(updatedInvitation)

      const { error } = await supabase
        .from('invitations')
        .update({
          ...updatedInvitation,
          addressNames,
          inviteesCount: inviteesCount.toString(),
          additionalGuests,
          totalGuests,
          replied,
          followUpNeeded
        })
        .eq('id', editingInvitation.id)
      
      if (error) {
        console.error('Error updating invitation:', error)
        toast.error('Nie udało się zaktualizować rekordu: ' + error.message)
        return
      }
      
      toast.success('Pomyślnie zapisano zmiany')
      fetchInvitations()
      setIsEditModalOpen(false)
      setEditingInvitation(null)
    } catch (err) {
      console.error('Exception while updating invitation:', err)
      toast.error('Wystąpił nieoczekiwany błąd podczas aktualizacji rekordu')
    }
  }

  const handleDeleteInvitation = async (id: string) => {
    const invitation = invitations.find(inv => inv.id === id)
    if (!invitation) return
    setDeleteModalInvitation(invitation)
  }

  const confirmDelete = async () => {
    if (!deleteModalInvitation) return

    try {
      const { error } = await supabase
        .from('invitations')
        .delete()
        .eq('id', 'id' in deleteModalInvitation ? deleteModalInvitation.id : '')
      
      if (error) {
        console.error('Error deleting invitation:', error)
        toast.error('Nie udało się usunąć rekordu: ' + error.message)
        return
      }
      
      toast.success('Pomyślnie usunięto zaproszenie')
      setDeleteModalInvitation(null)
      fetchInvitations()
    } catch (err) {
      console.error('Exception while deleting invitation:', err)
      toast.error('Wystąpił nieoczekiwany błąd podczas usuwania rekordu')
    }
  }

  const handleDeleteSelected = async () => {
    if (!selectedIds.length) return
    
    if (!window.confirm(`Czy na pewno chcesz usunąć ${selectedIds.length} zaznaczonych rekordów?`)) {
      return
    }

    try {
      const { error } = await supabase
        .from('invitations')
        .delete()
        .in('id', selectedIds)
      
      if (error) {
        console.error('Error deleting invitations:', error)
        toast.error('Nie udało się usunąć rekordów: ' + error.message)
        return
      }
      
      toast.success('Pomyślnie usunięto rekordy')
      setSelectedIds([])
      fetchInvitations()
    } catch (err) {
      console.error('Exception while deleting invitations:', err)
      toast.error('Wystąpił nieoczekiwany błąd podczas usuwania rekordów')
    }
  }

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortDirection('asc')
    }
  }

  const getSortedInvitations = () => {
    const filtered = getFilteredInvitations()
    return [...filtered].sort((a, b) => {
      // Always sort by lastNameA within groups (A-Z)
      const lastNameCompare = a.lastNameA.toLowerCase().localeCompare(b.lastNameA.toLowerCase())

      switch (sortField) {
        case 'lastNameA':
          return sortDirection === 'asc' ? lastNameCompare : -lastNameCompare

        case 'decision': {
          const decisionOrder = ['Tak', 'Nie', null]
          const indexA = decisionOrder.indexOf(a.decision)
          const indexB = decisionOrder.indexOf(b.decision)
          
          // If same decision, sort by lastName A-Z
          if (indexA === indexB) {
            return lastNameCompare
          }
          // Sort by decision order, direction affects only the decision order
          return sortDirection === 'asc' ? indexA - indexB : indexB - indexA
        }

        case 'replied': {
          // If same reply status, sort by lastName A-Z
          if (a.replied === b.replied) {
            return lastNameCompare
          }
          // Sort by reply status, direction affects only the reply status
          return sortDirection === 'asc' ? (a.replied ? -1 : 1) : (a.replied ? 1 : -1)
        }

        case 'followUpNeeded': {
          // If same followUp status, sort by lastName A-Z
          if (a.followUpNeeded === b.followUpNeeded) {
            return lastNameCompare
          }
          // Sort by followUp status, direction affects only the followUp status
          return sortDirection === 'asc' ? (a.followUpNeeded ? -1 : 1) : (a.followUpNeeded ? 1 : -1)
        }

        case 'group': {
          const groupA = GROUP_MAPPING[a.group as GroupKey].toLowerCase()
          const groupB = GROUP_MAPPING[b.group as GroupKey].toLowerCase()
          
          // If same group, sort by lastName A-Z
          if (groupA === groupB) {
            return lastNameCompare
          }
          // Sort by group name, direction affects only the group order
          return sortDirection === 'asc' ? groupA.localeCompare(groupB) : groupB.localeCompare(groupA)
        }

        default:
          return lastNameCompare
      }
    })
  }

  const SortIcon = ({ field }: { field: SortField }) => {
    if (sortField !== field) return <span className="sort-icon">↕</span>
    return <span className="sort-icon">{sortDirection === 'asc' ? '↑' : '↓'}</span>
  }

  const clearSearch = () => {
    setSearchTerm('')
    setMatchedRows([])
    setCurrentMatchIndex(0)
    // Remove highlight from all rows
    document.querySelectorAll('tbody tr').forEach(row => {
      row.classList.remove('highlight')
    })
  }

  const handleSearch = () => {
    if (!searchTerm) {
      clearSearch()
      return
    }

    const matches: number[] = []
    getSortedInvitations().forEach((inv, index) => {
      const searchFields = [
        inv.firstNameA,
        inv.lastNameA,
        inv.firstNameB,
        inv.lastNameB
      ].map(field => field?.toLowerCase() || '')

      if (searchFields.some(field => field.includes(searchTerm.toLowerCase()))) {
        matches.push(index)
      }
    })

    setMatchedRows(matches)
    if (matches.length > 0) {
      setCurrentMatchIndex(0)
      scrollToMatch(matches[0])
    } else {
      toast.info('Nie znaleziono wyników')
    }
  }

  const scrollToNextMatch = () => {
    if (matchedRows.length === 0) return
    const nextIndex = (currentMatchIndex + 1) % matchedRows.length
    setCurrentMatchIndex(nextIndex)
    scrollToMatch(matchedRows[nextIndex])
  }

  const scrollToMatch = (rowIndex: number) => {
    const rows = document.querySelectorAll('tbody tr')
    if (rows[rowIndex]) {
      rows[rowIndex].scrollIntoView({ behavior: 'smooth', block: 'center' })
      rows[rowIndex].classList.add('highlight')
      setTimeout(() => rows[rowIndex].classList.remove('highlight'), 2000)
    }
  }

  const handleSendInvitations = async () => {
    if (!isSendModalOpen) {
      setIsSendModalOpen(true);
      return;
    }

    setIsSending(true);
    let successCount = 0;
    let failCount = 0;

    const selectedInvitations = invitations.filter((inv: InvitationType) => selectedIds.includes(inv.id));
    
    // Start mock progress
    setSendingProgress(0);
    const mockProgress = setInterval(() => {
      setSendingProgress((prev: number) => {
        if (prev >= 90) {
          clearInterval(mockProgress)
          return 90
        }
        return prev + 10
      });
    }, 1000);

    try {
      await Promise.all(selectedInvitations.map(async (invitation: InvitationType) => {
        const emails = [
          invitation.emailA,
          invitation.emailB,
          invitation.emailC,
          invitation.emailD,
          invitation.emailE
        ].filter(Boolean) as string[];

        try {
          const success = await sendInvitation({
            nameA: invitation.firstNameA || '',
            lastNameA: invitation.lastNameA || '',
            firstNameB: invitation.firstNameB || undefined,
            lastNameB: invitation.lastNameB || undefined,
            emails: emails, // Pass all emails at once
            individualLink: invitation.individualLink,
            addressNames: invitation.addressNames || formatAddressNames(invitation)
          });

          if (success) {
            successCount++;
            
            // Update sending dates in Supabase
            const currentDate = new Date().toISOString();
            const sendingDates = [...(invitation.sendingDates || []), currentDate];

            await supabase
              .from('invitations')
              .update({ sendingDates })
              .eq('id', invitation.id);
          } else {
            failCount++;
          }
        } catch (err) {
          console.error('Error sending invitation:', err);
          failCount++;
        }
      }));

      // Complete the progress bar
      clearInterval(mockProgress);
      setSendingProgress(100);

      if (successCount > 0) {
        toast.success(`Pomyślnie wysłano ${successCount} zaproszeń`);
        await fetchInvitations();
      }
      if (failCount > 0) {
        toast.error(`Nie udało się wysłać ${failCount} zaproszeń`);
      }
    } catch (err) {
      console.error('Error in send process:', err);
    } finally {
      setTimeout(() => {
        setIsSending(false);
        setSendingProgress(0);
        setIsSendModalOpen(false);
        setIsActionsModalOpen(false);
      }, 500);
    }
  };

  const handleDeleteLinks = async () => {
    try {
      setIsDeletingLinks(true)
      const invitationsToUpdate = invitations.filter((inv: InvitationType) => inv.individualLink)
      const total = invitationsToUpdate.length
      let processed = 0

      for (const invitation of invitationsToUpdate) {
        try {
          const { error } = await supabase
            .from('invitations')
            .update({ individualLink: null })
            .eq('id', invitation.id)

          if (error) throw error
          processed++
          setDeleteLinksProgress((processed / total) * 100)
        } catch (err) {
          console.error('Error deleting link:', err)
          toast.error(`Błąd podczas usuwania linku dla ${invitation.firstNameA} ${invitation.lastNameA}`)
        }
      }

      toast.success('Wszystkie linki zostały usunięte')
      await fetchInvitations()
    } catch (err) {
      console.error('Error in handleDeleteLinks:', err)
      toast.error('Wystąpił błąd podczas usuwania linków')
    } finally {
      setIsDeletingLinks(false)
      setDeleteLinksProgress(0)
      setIsGenerateLinksModalOpen(false)
    }
  }

  const getSummaryStats = () => {
    const filtered = getFilteredInvitations();
    const replied = {
      tak: filtered.filter(inv => inv.replied).length,
      nie: filtered.filter(inv => !inv.replied).length
    };
    const decision = {
      tak: filtered.filter(inv => inv.decision === 'Tak').length,
      nie: filtered.filter(inv => inv.decision === 'Nie').length
    };
    const paperInvitation = {
      tak: filtered.filter(inv => inv.paperInvitation).length,
      nie: filtered.filter(inv => !inv.paperInvitation).length
    };
    const sent = {
      tak: filtered.filter(inv => inv.sendingDates && inv.sendingDates.length > 0).length,
      nie: filtered.filter(inv => !inv.sendingDates || inv.sendingDates.length === 0).length
    };
    const confirmedGuests = {
      tak: filtered
        .filter(inv => inv.replied && inv.decision === 'Tak')
        .reduce((sum, inv) => sum + parseInt(inv.totalGuests), 0),
      nie: filtered
        .filter(inv => !inv.replied || inv.decision !== 'Tak')
        .reduce((sum, inv) => sum + parseInt(inv.totalGuests), 0)
    };

    const calculatePercentage = (yes: number, no: number) => {
      const total = yes + no;
      if (total === 0) return 0;
      return Math.round((yes / total) * 100);
    };

    return {
      replied: {
        ...replied,
        takPercentage: calculatePercentage(replied.tak, replied.nie),
        niePercentage: calculatePercentage(replied.nie, replied.tak)
      },
      decision: {
        ...decision,
        takPercentage: calculatePercentage(decision.tak, decision.nie),
        niePercentage: calculatePercentage(decision.nie, decision.tak)
      },
      paperInvitation: {
        ...paperInvitation,
        takPercentage: calculatePercentage(paperInvitation.tak, paperInvitation.nie),
        niePercentage: calculatePercentage(paperInvitation.nie, paperInvitation.tak)
      },
      sent: {
        ...sent,
        takPercentage: calculatePercentage(sent.tak, sent.nie),
        niePercentage: calculatePercentage(sent.nie, sent.tak)
      },
      confirmedGuests: {
        ...confirmedGuests,
        takPercentage: calculatePercentage(confirmedGuests.tak, confirmedGuests.nie),
        niePercentage: calculatePercentage(confirmedGuests.nie, confirmedGuests.tak)
      }
    };
  };

  return (
    <Wrapper>
      <ToastContainer position="top-right" />
      <StickyHeader>
        <HeaderSection>
          <Title>Zaproszenia</Title>
          <Summary>
          <div>
              <SummaryTable>
                <thead>
                  <tr>
                    <th>Wysłane</th>
                    <th>Tak</th>
                    <th>Nie</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <SummaryCell type="tak">
                      {getSummaryStats().sent.tak}<br/>
                      <span className="percentage">({getSummaryStats().sent.takPercentage}%)</span>
                    </SummaryCell>
                    <SummaryCell type="nie">
                      {getSummaryStats().sent.nie}<br/>
                      <span className="percentage">({getSummaryStats().sent.niePercentage}%)</span>
                    </SummaryCell>
                  </tr>
                </tbody>
              </SummaryTable>
            </div>
            <div>
              <SummaryTable>
                <thead>
                  <tr>
                    <th>Odpowiedź</th>
                    <th>Tak</th>
                    <th>Nie</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <SummaryCell type="tak">
                      {getSummaryStats().replied.tak}<br/>
                      <span className="percentage">({getSummaryStats().replied.takPercentage}%)</span>
                    </SummaryCell>
                    <SummaryCell type="nie">
                      {getSummaryStats().replied.nie}<br/>
                      <span className="percentage">({getSummaryStats().replied.niePercentage}%)</span>
                    </SummaryCell>
                  </tr>
                </tbody>
              </SummaryTable>
            </div>
            <div>
              <SummaryTable>
                <thead>
                  <tr>
                    <th>Decyzja</th>
                    <th>Tak</th>
                    <th>Nie</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <SummaryCell type="tak">
                      {getSummaryStats().decision.tak}<br/>
                      <span className="percentage">({getSummaryStats().decision.takPercentage}%)</span>
                    </SummaryCell>
                    <SummaryCell type="nie">
                      {getSummaryStats().decision.nie}<br/>
                      <span className="percentage">({getSummaryStats().decision.niePercentage}%)</span>
                    </SummaryCell>
                  </tr>
                </tbody>
              </SummaryTable>
            </div>
            <div>
              <SummaryTable>
                <thead>
                  <tr>
                    <th>Zaproszenie:</th>
                    <th>Papierowe</th>
                    <th>@</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <SummaryCell type="tak">
                      {getSummaryStats().paperInvitation.tak}<br/>
                      <span className="percentage">({getSummaryStats().paperInvitation.takPercentage}%)</span>
                    </SummaryCell>
                    <SummaryCell type="nie">
                      {getSummaryStats().paperInvitation.nie}<br/>
                      <span className="percentage">({getSummaryStats().paperInvitation.niePercentage}%)</span>
                    </SummaryCell>
                  </tr>
                </tbody>
              </SummaryTable>
            </div>
            <div>
              <SummaryTable>
                <thead>
                  <tr>
                    <th>Potwierdzeni goście</th>
                    <th>Tak</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <SummaryCell type="tak">
                      {getSummaryStats().confirmedGuests.tak}<br/>
                      <span className="percentage">({getSummaryStats().confirmedGuests.takPercentage}%)</span>
                    </SummaryCell>
                   
                  </tr>
                </tbody>
              </SummaryTable>
            </div>
          </Summary>
          <BackButton 
            variant="outlined"
            onClick={() => navigate('/admin')}
          >
            Powrót
          </BackButton>
        </HeaderSection>
        
        <FiltersSection>
          <FiltersArea>
            <FilterGroup>
              <FilterLabel>Odpowiedzi</FilterLabel>
              <FilterSelect 
                value={filters.replied} 
                onChange={e => setFilters({...filters, replied: e.target.value as 'all' | 'true' | 'false'})}>
                <option value="all">Wszystkie odpowiedzi</option>
                <option value="true">Odpowiedziane</option>
                <option value="false">Bez odpowiedzi</option>
              </FilterSelect>
            </FilterGroup>

            <FilterGroup>
              <FilterLabel>Decyzja</FilterLabel>
              <FilterSelect 
                value={filters.decision || 'all'}
                onChange={e => setFilters({...filters, decision: e.target.value as Decision})}>
                <option value="all">Wszystkie decyzje</option>
                <option value="Tak">Tak</option>
                <option value="Nie">Nie</option>
              </FilterSelect>
            </FilterGroup>

            <FilterGroup>
              <FilterLabel>Follow-up</FilterLabel>
              <FilterSelect 
                value={filters.followUpNeeded} 
                onChange={e => setFilters({...filters, followUpNeeded: e.target.value as 'all' | 'true' | 'false'})}>
                <option value="all">Wszystkie</option>
                <option value="true">Wymaga follow-up</option>
                <option value="false">Nie wymaga follow-up</option>
              </FilterSelect>
            </FilterGroup>

            <FilterGroup>
              <FilterLabel>Wersja zaproszenia</FilterLabel>
              <FilterSelect 
                value={filters.paperInvitation} 
                onChange={e => setFilters({...filters, paperInvitation: e.target.value as 'all' | 'true' | 'false'})}>
                <option value="all">Wszystkie wersje</option>
                <option value="true">Papierowe</option>
                <option value="false">Elektroniczne</option>
              </FilterSelect>
            </FilterGroup>

            <FilterGroup>
              <FilterLabel>Grupa</FilterLabel>
              <FilterSelect 
                value={filters.group}
                onChange={e => setFilters({...filters, group: e.target.value})}>
                <option value="">Wszystkie grupy</option>
                {Object.entries(GROUP_MAPPING).map(([key, label]) => (
                  <option key={key} value={key}>{label}</option>
                ))}
              </FilterSelect>
            </FilterGroup>

            <FilterGroup>
              <FilterLabel>&nbsp;</FilterLabel>
              <ClearButton 
                variant="outlined"
                onClick={() => setFilters({
                  decision: 'all',
                  replied: 'all',
                  paperInvitation: 'all',
                  followUpNeeded: 'all',
                  group: ''
                })}
              >
                Wyczyść filtry
              </ClearButton>
            </FilterGroup>
          </FiltersArea>
          
          <ActionsArea>
            <Button
              variant="outlined"
              onClick={() => setIsActionsModalOpen(true)}
              disabled={selectedIds.length === 0}
            >
              Akcje
            </Button>
            <Button
              variant="outlined"
              onClick={() => setIsGenerateLinksModalOpen(true)}
              disabled={generateLinksProgress > 0}
              color="error"
            >
              {generateLinksProgress > 0
                ? `Generowanie linków... ${generateLinksProgress}%`
                : 'Generuj linki'}
            </Button>
            <Button
              variant="outlined"
              onClick={() => setIsDeleteLinksModalOpen(true)}
              disabled={invitations.filter((inv: InvitationType) => inv.individualLink).length === 0}
              color="error"
            >
              Kasuj linki
            </Button>
            <Button
              variant="outlined"
              onClick={handleUpdateAddressNames}
              disabled={invitations.length === 0}
            >
              Aktualizuj zwroty adresowe
            </Button>
            <AddButton
              variant="contained"
              onClick={() => setIsAddModalOpen(true)}
            >
              Dodaj zaproszenie
            </AddButton>
          </ActionsArea>
        </FiltersSection>

        <SearchBar>
          <SearchInput
            placeholder="Szukaj po imieniu lub nazwisku..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value)
              if (!e.target.value) {
                clearSearch()
              }
            }}
            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          />
          <SearchButton
            variant="outlined"
            onClick={handleSearch}
          >
            Szukaj
          </SearchButton>
          {searchTerm && (
            <SearchButton
              variant="outlined"
              onClick={clearSearch}
            >
              Wyczyść
            </SearchButton>
          )}
          {matchedRows.length > 0 && (
            <>
              <SearchButton
                variant="outlined"
                onClick={scrollToNextMatch}
              >
                Następny ({currentMatchIndex + 1}/{matchedRows.length})
              </SearchButton>
              <span className="search-count">Znaleziono: {matchedRows.length}</span>
            </>
          )}
        </SearchBar>
      </StickyHeader>

      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <th>
                <input 
                  type="checkbox" 
                  checked={selectedIds.length === getFilteredInvitations().length}
                  onChange={handleSelectAll}
                />
              </th>
              <th>Akcje</th>
              <SortableHeader onClick={() => handleSort('group')}>
                Grupa <SortIcon field="group" />
              </SortableHeader>
              <SortableHeader onClick={() => handleSort('lastNameA')}>
                Osoba 1 <SortIcon field="lastNameA" />
              </SortableHeader>
              <th>Osoba 2</th>
              <th>Emaile</th>
              <SortableHeader onClick={() => handleSort('replied')}>
                Odpowiedź <SortIcon field="replied" />
              </SortableHeader>
              <SortableHeader onClick={() => handleSort('decision')}>
                Decyzja <SortIcon field="decision" />
              </SortableHeader>
              <th>Wersja papierowa</th>
              <th>Liczba zaproszonych
                <ColumnTotal>
                  ({getFilteredInvitations().reduce((sum: number, inv: InvitationType) => sum + parseInt(inv.inviteesCount), 0)})
                </ColumnTotal>
              </th>
              <th>Osoby dodatkowe
                <ColumnTotal>
                  ({getFilteredInvitations().reduce((sum: number, inv: InvitationType) => sum + parseInt(inv.additionalGuests || '0'), 0)})
                </ColumnTotal>
              </th>
              <th>Suma gości
                <ColumnTotal>
                  ({getFilteredInvitations().reduce((sum: number, inv: InvitationType) => sum + parseInt(inv.totalGuests), 0)})
                </ColumnTotal>
              </th>
              <SortableHeader onClick={() => handleSort('followUpNeeded')}>
                Wymaga follow-up <SortIcon field="followUpNeeded" />
              </SortableHeader>
              <th>Daty wysyłki</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            {getSortedInvitations().map((invitation, index) => (
              <TableRow key={invitation.id} className={matchedRows.includes(index) ? 'highlight' : ''}>
                <td>
                  <input 
                    type="checkbox"
                    checked={selectedIds.includes(invitation.id)}
                    onChange={() => handleSelectOne(invitation.id)}
                  />
                </td>
                <td>
                  <ActionsCell>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setEditingInvitation(invitation)
                        setIsEditModalOpen(true)
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteInvitation(invitation.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ActionsCell>
                </td>
                <td>{GROUP_MAPPING[invitation.group as GroupKey] || invitation.group}</td>
                <td>{`${invitation.firstNameA} ${invitation.lastNameA}`}</td>
                <td>{invitation.firstNameB ? `${invitation.firstNameB} ${invitation.lastNameB}` : ''}</td>
                <td>
                  <EmailsCell>
                    {[
                      invitation.emailA,
                      invitation.emailB,
                      invitation.emailC,
                      invitation.emailD,
                      invitation.emailE
                    ]
                      .filter(email => email)
                      .map((email, index) => (
                        <div key={index}>{email}</div>
                      ))}
                  </EmailsCell>
                </td>
                <td>{invitation.replied ? 'Tak' : 'Nie'}</td>
                <DecisionCell decision={invitation.decision}>{invitation.decision || '-'}</DecisionCell>
                <td>{invitation.paperInvitation ? 'Tak' : 'Nie'}</td>
                <td>{invitation.inviteesCount}</td>
                <td>
                  <select
                    value={invitation.additionalGuests}
                    onChange={async (e) => {
                      const newValue = e.target.value
                      const inviteesCount = parseInt(invitation.inviteesCount)
                      const totalGuests = (inviteesCount + parseInt(newValue)).toString()
                      
                      try {
                        const { error } = await supabase
                          .from('invitations')
                          .update({
                            additionalGuests: newValue,
                            totalGuests: totalGuests
                          })
                          .eq('id', invitation.id)
                        
                        if (error) {
                          toast.error('Nie udało się zaktualizować liczby gości')
                        } else {
                          setInvitations((prevInvitations: InvitationType[]) =>
                            prevInvitations.map((inv: InvitationType) =>
                              inv.id === invitation.id
                                ? { ...inv, additionalGuests: newValue, totalGuests: totalGuests }
                                : inv
                            )
                          )
                          await fetchInvitations()
                        }
                      } catch (err) {
                        toast.error('Wystąpił błąd podczas aktualizacji liczby gości')
                      }
                    }}
                  >
                    {ADDITIONAL_GUESTS_OPTIONS.map(num => (
                      <option key={num} value={num}>{num}</option>
                    ))}
                  </select>
                </td>
                <td>{parseInt(invitation.inviteesCount) + parseInt(invitation.additionalGuests || '0')}</td>
                <td>{invitation.followUpNeeded ? 'Tak' : 'Nie'}</td>
                <td>
                  <DatesCell>
                    {(invitation.sendingDates || [])
                      .sort((dateA: string, dateB: string) => new Date(dateB).getTime() - new Date(dateA).getTime())
                      .map((date: string, index: number) => (
                        <div key={index}>{new Date(date).toLocaleDateString('pl-PL')}</div>
                      ))}
                  </DatesCell>
                </td>
                <td>{invitation.individualLink}</td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableWrapper>

      <AddInvitationModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSubmit={handleAddInvitation}
      />

      {editingInvitation && (
        <AddInvitationModal
          open={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false)
            setEditingInvitation(null)
          }}
          onSubmit={handleEditInvitation}
          initialData={editingInvitation}
        />
      )}

      <ActionsModal
        open={isActionsModalOpen}
        onClose={() => setIsActionsModalOpen(false)}
        selectedInvitations={invitations.filter((inv: InvitationType) => selectedIds.includes(inv.id))}
        onDelete={fetchInvitations}
        fetchInvitations={fetchInvitations}
        formatAddressNames={formatAddressNames}
      />

      <DeleteConfirmationModal
        open={!!deleteModalInvitation}
        onClose={() => setDeleteModalInvitation(null)}
        onConfirm={confirmDelete}
        invitation={deleteModalInvitation}
      />

      <Modal
        open={isGenerateLinksModalOpen}
        onClose={() => !generateLinksProgress && setIsGenerateLinksModalOpen(false)}
      >
        <ModalContent style={{ maxWidth: 500 }}>
          <h2>Potwierdź generowanie linków</h2>
          {!generateLinksProgress ? (
            <>
              <p style={{ marginBottom: 24 }}>
                Czy na pewno chcesz wygenerować linki dla wszystkich zaproszeń bez linków? Tej operacji nie można cofnąć.
              </p>
              <ModalButtons>
                <Button 
                  variant="outlined" 
                  onClick={() => setIsGenerateLinksModalOpen(false)}
                  disabled={generateLinksProgress > 0}
                >
                  Anuluj
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleGenerateLinks}
                  disabled={generateLinksProgress > 0}
                >
                  Generuj linki
                </Button>
              </ModalButtons>
            </>
          ) : (
            <div>
              <p style={{ marginBottom: 16 }}>Generowanie linków...</p>
              <LinearProgress variant="determinate" value={generateLinksProgress} />
              <p style={{ marginTop: 8, textAlign: 'center' }}>
                {generateLinksProgress}%
              </p>
            </div>
          )}
        </ModalContent>
      </Modal>

      <Modal
        open={isDeleteLinksModalOpen}
        onClose={() => !isDeletingLinks && setIsDeleteLinksModalOpen(false)}
      >
        <ModalContent style={{ maxWidth: 500 }}>
          <h2>Usuń wszystkie linki</h2>
          {!isDeletingLinks ? (
            <>
              <p style={{ marginBottom: 24 }}>
                Czy na pewno chcesz usunąć wszystkie linki z zaproszeń? Tej operacji nie można cofnąć.
              </p>
              <ModalButtons>
                <Button
                  variant="outlined"
                  onClick={() => setIsDeleteLinksModalOpen(false)}
                  disabled={isDeletingLinks}
                >
                  Anuluj
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDeleteLinks}
                  disabled={isDeletingLinks}
                >
                  Usuń linki
                </Button>
              </ModalButtons>
            </>
          ) : (
            <div>
              <p style={{ marginBottom: 16 }}>Usuwanie linków...</p>
              <LinearProgress variant="determinate" value={deleteLinksProgress} />
              <p style={{ marginTop: 8, textAlign: 'center' }}>
                {Math.round(deleteLinksProgress)}%
              </p>
            </div>
          )}
        </ModalContent>
      </Modal>
    </Wrapper>
  )
}

export default Invitations

interface AddInvitationModalProps {
  open: boolean
  onClose: () => void
  onSubmit: (invitation: Partial<InvitationType>) => void
  initialData?: InvitationType
}

const AddInvitationModal = ({ open, onClose, onSubmit, initialData }: AddInvitationModalProps) => {
  const defaultFormData: Partial<InvitationType> = {
    firstNameA: '',
    lastNameA: '',
    firstNameB: '',
    lastNameB: '',
    emailA: '',
    emailB: '',
    emailC: '',
    emailD: '',
    emailE: '',
    group: 'other' as GroupKey,
    decision: null,
    replied: false,
    paperInvitation: true,
    followUpNeeded: true
  }

  const [formData, setFormData] = useState<Partial<InvitationType>>(initialData || defaultFormData)

  // Reset form when modal opens
  useEffect(() => {
    if (open) {
      setFormData(initialData || defaultFormData)
    }
  }, [open, initialData])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const submitData = { ...formData }
    
    // Handle decision-based updates
    const hasDecision = !!submitData.decision
    submitData.replied = hasDecision
    submitData.followUpNeeded = !hasDecision && shouldFollowUp(submitData.sendingDates || [])

    if (submitData.decision === 'Nie') {
      submitData.additionalGuests = '0'
      submitData.totalGuests = '0'
    }
    
    onSubmit(submitData)
  }

  const handleTextChange = (field: keyof InvitationType) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData(prev => ({ ...prev, [field]: e.target.value }))
  }

  const handleSelectChange = (field: keyof InvitationType) => (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = e.target.value
    setFormData(prev => {
      if (field === 'decision') {
        const hasDecision = value !== ''
        return {
          ...prev,
          decision: hasDecision ? value as Decision : null,
          replied: hasDecision,
          followUpNeeded: !hasDecision && shouldFollowUp(prev.sendingDates || [])
        }
      }
      return { ...prev, [field]: value }
    })
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContent>
        <h2>{initialData ? 'Edytuj zaproszenie' : 'Dodaj nowe osoby'}</h2>
        <form onSubmit={handleSubmit}>
          <ModalGrid>
            <TextField
              label="Imię A"
              value={formData.firstNameA}
              onChange={handleTextChange('firstNameA')}
              required
            />
            <TextField
              label="Nazwisko A"
              value={formData.lastNameA}
              onChange={handleTextChange('lastNameA')}
              required
            />
            <TextField
              label="Imię B"
              value={formData.firstNameB}
              onChange={handleTextChange('firstNameB')}
            />
            <TextField
              label="Nazwisko B"
              value={formData.lastNameB}
              onChange={handleTextChange('lastNameB')}
            />
            <TextField
              label="Email A"
              type="email"
              value={formData.emailA}
              onChange={handleTextChange('emailA')}
              required
            />
            <TextField
              label="Email B"
              type="email"
              value={formData.emailB}
              onChange={handleTextChange('emailB')}
            />
            <TextField
              label="Email C"
              type="email"
              value={formData.emailC}
              onChange={handleTextChange('emailC')}
            />
            <TextField
              label="Email D"
              type="email"
              value={formData.emailD}
              onChange={handleTextChange('emailD')}
            />
            <TextField
              label="Email E"
              type="email"
              value={formData.emailE}
              onChange={handleTextChange('emailE')}
            />
            <FormSelect
              value={formData.group as GroupKey}
              onChange={handleSelectChange('group')}
              required
            >
              {Object.entries(GROUP_MAPPING).map(([key, label]) => (
                <option key={key} value={key}>{label}</option>
              ))}
            </FormSelect>
            
            <FilterGroup style={{ gridColumn: '1 / -1' }}>
              <FilterLabel>Decyzja o uczestnictwie</FilterLabel>
              <FormSelect
                value={formData.decision || ''}
                onChange={handleSelectChange('decision')}
              >
                <option value="">Brak odpowiedzi</option>
                <option value="Tak">Tak</option>
                <option value="Nie">Nie</option>
              </FormSelect>
            </FilterGroup>
            
            <FormCheckbox>
              <input
                type="checkbox"
                checked={formData.paperInvitation}
                onChange={(e) => setFormData((prev: Partial<InvitationType>) => ({ ...prev, paperInvitation: e.target.checked }))}
              />
              <label>Wersja papierowa</label>
            </FormCheckbox>
          </ModalGrid>
          <ModalActions>
            <Button variant="outlined" onClick={onClose}>
              Anuluj
            </Button>
            <Button variant="contained" type="submit">
              {initialData ? 'Zapisz' : 'Dodaj'}
            </Button>
          </ModalActions>
        </form>
      </ModalContent>
    </Modal>
  )
}

interface ActionsModalProps {
  open: boolean;
  onClose: () => void;
  selectedInvitations: InvitationType[];
  onDelete: () => Promise<void>;
  fetchInvitations: () => Promise<void>;
  formatAddressNames: (invitation: Partial<InvitationType>) => string;
}

const ActionsModal = ({ open, onClose, selectedInvitations, onDelete, fetchInvitations, formatAddressNames }: ActionsModalProps) => {
  const sortedInvitations = [...selectedInvitations].sort((a, b) => 
    a.lastNameA.toLowerCase().localeCompare(b.lastNameA.toLowerCase())
  )
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isSendModalOpen, setIsSendModalOpen] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [sendingProgress, setSendingProgress] = useState(0)

  const handleExport = () => {
    // TODO: Implement export functionality
    toast.info('Funkcja eksportu będzie dostępna wkrótce')
  }

  const handleSendInvitations = async () => {
    if (!isSendModalOpen) {
      setIsSendModalOpen(true);
      return;
    }

    setIsSending(true);
    let successCount = 0;
    let failCount = 0;

    for (const [index, invitation] of selectedInvitations.entries()) {
      const emails = [
        invitation.emailA,
        invitation.emailB,
        invitation.emailC,
        invitation.emailD,
        invitation.emailE
      ].filter(Boolean) as string[];

      // Debug log
      console.log('Sending to emails:', emails);
      
      if (emails.length === 0) {
        console.error('No valid email addresses found for:', invitation);
        failCount++;
        continue;
      }

      try {
        const success = await sendInvitation({
          nameA: invitation.firstNameA || '',
          lastNameA: invitation.lastNameA || '',
          firstNameB: invitation.firstNameB || undefined,
          lastNameB: invitation.lastNameB || undefined,
          emails: emails,
          individualLink: invitation.individualLink,
          addressNames: invitation.addressNames || formatAddressNames(invitation)
        });

        if (success) {
          successCount++;
          
          // Update sending dates in Supabase
          const currentDate = new Date().toISOString();
          const sendingDates = [...(invitation.sendingDates || []), currentDate];

          await supabase
            .from('invitations')
            .update({ sendingDates })
            .eq('id', invitation.id);
        } else {
          failCount++;
        }

        // Update progress
        setSendingProgress(Math.round(((index + 1) / selectedInvitations.length) * 100));
      } catch (err) {
        console.error('Error sending invitation:', err);
        failCount++;
      }
    }

    if (successCount > 0) {
      toast.success(`Pomyślnie wysłano ${successCount} zaproszeń`);
      await fetchInvitations();
    }
    if (failCount > 0) {
      toast.error(`Nie udało się wysłać ${failCount} zaproszeń`);
    }
    
    setIsSending(false);
    setSendingProgress(0);
    setIsSendModalOpen(false);
    onClose();
  };

  const handleDeleteSelected = async () => {
    try {
      const { error } = await supabase
        .from('invitations')
        .delete()
        .in('id', selectedInvitations.map(inv => inv.id))
      
      if (error) {
        console.error('Error deleting invitations:', error)
        toast.error('Nie udało się usunąć rekordów: ' + error.message)
        return
      }
      
      toast.success('Pomyślnie usunięto rekordy')
      onClose()
      await onDelete()
    } catch (err) {
      console.error('Exception while deleting invitations:', err)
      toast.error('Wystąpił nieoczekiwany błąd podczas usuwania rekordów')
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContent>
        <h2>Akcje grupowe</h2>
        <ActionsToolbar>
          <DeleteButton 
            variant="contained"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            Usuń zaznaczone ({selectedInvitations.length})
          </DeleteButton>
          <ActionButton onClick={handleExport}>
            Eksport
          </ActionButton>
          <ActionButton 
            onClick={handleSendInvitations}
            disabled={isSending}
          >
            {isSending ? 'Wysyłanie...' : 'Wyślij zaproszenia'}
          </ActionButton>
        </ActionsToolbar>
        
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <th>Osoba</th>
                <th>Link</th>
                <th>Grupa</th>
              </tr>
            </thead>
            <tbody>
              {sortedInvitations.map(invitation => (
                <tr key={invitation.id}>
                  <td>
                    {`${invitation.firstNameA} ${invitation.lastNameA}`}
                    {invitation.firstNameB && ` | ${invitation.firstNameB} ${invitation.lastNameB}`}
                  </td>
                  <td>{invitation.individualLink}</td>
                  <td>{GROUP_MAPPING[invitation.group as GroupKey]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>

        <Modal open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
          <ModalContent style={{ maxWidth: 500 }}>
            <h2>Potwierdź usunięcie</h2>
            <p style={{ marginBottom: 24 }}>
              Czy na pewno chcesz usunąć {selectedInvitations.length} zaznaczonych rekordów?
            </p>
            <ModalActions>
              <Button variant="outlined" onClick={() => setIsDeleteModalOpen(false)}>
                Anuluj
              </Button>
              <DeleteButton 
                variant="contained" 
                onClick={() => {
                  handleDeleteSelected()
                  setIsDeleteModalOpen(false)
                }}
              >
                Usuń
              </DeleteButton>
            </ModalActions>
          </ModalContent>
        </Modal>

        <Modal open={isSendModalOpen} onClose={() => !isSending && setIsSendModalOpen(false)}>
          <ModalContent style={{ maxWidth: 500 }}>
            <h2>Potwierdź wysyłanie</h2>
            {!isSending ? (
              <>
                <p style={{ marginBottom: 24 }}>
                  Czy na pewno chcesz wysłać zaproszenia do {selectedInvitations.length} osób?
                </p>
                <ModalActions>
                  <Button variant="outlined" onClick={() => setIsSendModalOpen(false)}>
                    Anuluj
                  </Button>
                  <ActionButton 
                    variant="contained" 
                    onClick={handleSendInvitations}
                  >
                    Wyślij
                  </ActionButton>
                </ModalActions>
              </>
            ) : (
              <div>
                <p style={{ marginBottom: 16 }}>Wysyłanie zaproszeń...</p>
                <LinearProgress variant="determinate" value={sendingProgress} />
                <p style={{ marginTop: 8, textAlign: 'center' }}>
                  {sendingProgress}%
                </p>
              </div>
            )}
          </ModalContent>
        </Modal>
      </ModalContent>
    </Modal>
  )
}

interface DeleteConfirmationModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  invitation: InvitationType | { count: number } | null
}

const DeleteConfirmationModal = ({ open, onClose, onConfirm, invitation }: DeleteConfirmationModalProps) => {
  if (!invitation) return null

  const getMessage = () => {
    if ('count' in invitation) {
      return `Czy na pewno chcesz usunąć ${invitation.count} zaznaczonych rekordów?`
    }
    let name = `${invitation.firstNameA} ${invitation.lastNameA}`
    if (invitation.firstNameB) {
      name += ` i ${invitation.firstNameB} ${invitation.lastNameB}`
    }
    return `Czy na pewno chcesz usunąć zaproszenie dla: ${name}`
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContent style={{ maxWidth: 500 }}>
        <h2>Potwierdź usunięcie</h2>
        <p style={{ marginBottom: 24 }}>{getMessage()}</p>
        <ModalActions>
          <Button variant="outlined" onClick={onClose}>
            Anuluj
          </Button>
          <DeleteButton variant="contained" onClick={onConfirm}>
            Usuń
          </DeleteButton>
        </ModalActions>
      </ModalContent>
    </Modal>
  )
}

const Wrapper = styled.div`
  padding: 20px;
  min-width: 1200px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
`

const ActionsContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
`