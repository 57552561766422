import React, { useEffect, useState } from 'react'
import { supabase } from 'utils/supabase'
import { VoterTypes } from 'admin/pages/Voters'
import ResultsPresentationTable from 'admin/components/ResultsPresentationTable'
import styled from 'styled-components'

const ResultsPresentation = () => {
  const [voters, setVoters] = useState<VoterTypes[] | []>([])

  const getVoters = async () => {
    try {
      const response = await supabase
        .from('voters')
        .select()

      const data = response.data as VoterTypes[]
      setVoters(data)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    // Initial fetch
    getVoters()

    // Set up polling every second
    const interval = setInterval(() => {
      getVoters()
    }, 1000)

    // Cleanup interval on component unmount
    return () => clearInterval(interval)
  }, [])

  return (
    <Wrapper>
      <Title>Prezentacja wyników</Title>
      { voters && <ResultsPresentationTable voters={voters} />}
    </Wrapper>
  )
}

export default ResultsPresentation

const Wrapper = styled.div`
  padding: 20px;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 30px;
` 