import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { supabase } from 'utils/supabase'
import { VoterTypes } from 'admin/pages/Voters'
import styled from 'styled-components'
import { stateTypes } from 'admin/pages/Candidates'
import { isNilOrEmpty } from 'utils/ramda'
import { CircularProgress } from '@mui/material'
import VotingTable, { VotingTableRowTypes } from 'admin/components/VotingTable'
import toast from 'react-hot-toast'
import Modal from 'components/Modal'
import Button from 'components/Button'
import { mobileCss } from 'utils/theme'


const initialValues = {
  success: [],
  innovation: [],
  charity: []
}

const Voting = () => {
  const [voter, setVoter] = useState<VoterTypes | undefined>(undefined)
  const [candidates, setCandidates] = useState<stateTypes>(initialValues)
  const [votes, setVotes] = useState<{ [key: string]: { score: string, nominee: string } }>({})
  const [loading, setLoading] = useState(isNilOrEmpty(candidates))
  const { code } = useParams()
  const navigate = useNavigate()
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const isSaved = voter?.voting_saved || false
  const [isVotingActive, setIsVotingActive] = useState(false)

  const handleConfirmModalOpen = () => setConfirmModalOpen(true)
  const handleConfirmModalClose = () => setConfirmModalOpen(false)

  useEffect(() => {
    if (candidates && voter) {
      const group = [...candidates.success, ...candidates.charity, ...candidates.innovation]

      const initialValues = group.reduce((acc, candidate) => {
        const { nominee, id } = candidate
        const existingVote = voter?.votes[id]

        if (existingVote) {
          // @ts-ignore
          acc[id] = {
            nominee,
            score: existingVote.score
          }
        } else {
          // @ts-ignore
          acc[id] = {
            nominee,
            score: '0'
          }
        }

        return acc
      }, {})

      setVotes(initialValues)
    }
  }, [candidates, voter])

  const getVoter = async () => {
    try {
      const response = await supabase
        .from('voters')
        .select()
        .eq('code', code)

      const voterData = response.data ? response.data[0] : null

      if (voterData) {
        setVoter(voterData)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const getSettings = async () => {
    try {
      const response = await supabase
        .from('panel_activity')
        .select()
        .eq('slug', 'settings')

      const settingsData = response.data ? response.data[0] : null

      if (settingsData) {
        setIsVotingActive(settingsData.voting_active)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getVoter()
    getSettings()
  }, [])

  const getCandidates = async () => {
    try {
      const response = await supabase
        .from('candidates')
        .select()
        .eq('finalist', true)

      const data = response.data as VotingTableRowTypes[]

      const success = data.filter(candidate => candidate.type === 'success')
      const innovation = data.filter(candidate => candidate.type === 'innovation')
      const charity = data.filter(candidate => candidate.type === 'charity')

      setCandidates({
        success,
        innovation,
        charity
      })
      setLoading(false)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getCandidates()
  }, [])

  const handleUpdateVote = async (candidateId: string, score: string) => {
    if (!voter) return;

    // First update local state
    const updatedVotes = {
      ...votes,
      [candidateId]: {
        nominee: candidates.success.find(c => c.id === candidateId)?.nominee ||
                 candidates.innovation.find(c => c.id === candidateId)?.nominee ||
                 candidates.charity.find(c => c.id === candidateId)?.nominee || '',
        score
      }
    }
    setVotes(updatedVotes)

    // Then immediately save to database
    try {
      await supabase
        .from('voters')
        .update({
          votes: updatedVotes
        })
        .eq('id', voter.id)

      toast.success('Głos został zapisany')
    } catch (err) {
      toast.error('Wystąpił błąd podczas zapisu')
      console.error(err)
    }
  }

  return loading
    ? (
      <EmptyState>
        <CircularProgress size={60} />
      </EmptyState>
    )
    : isVotingActive
      ? (
      <ContentWrapper>
        Witaj, {voter?.name} - głosujesz z linku /kapitula/{voter?.code}
        <Header>
          <InfoWrapper>
            <Title>
              Booklet dla Kapituły 14. edycji Nagrody PRB
            </Title>
            <HeadLine>Zapraszamy do zapoznania się z listą kandydatur{' '}
              <InlineLink href='/finalisci/wszyscy'>
                TUTAJ{' '}
              </InlineLink>. Prosimy o przyznanie przy każdej z nich punktów w skali 0-4, gdzie 4 to najwyższa nota. Kandydaci, którym nie przyznano punktów, otrzymują automatycznie notę 0.
            </HeadLine>
          </InfoWrapper>
          {/*<Button variant='outlined' onClick={() => navigate('/broszura/wszyscy')}>Zobacz broszurę zbiorczą</Button>*/}
        </Header>
        <TableWrapper>
          <TableTitle>
            Sukces
          </TableTitle>
          <VotingTable
            onVote={handleUpdateVote}
            votes={votes}
            candidates={candidates.success}
            voter={voter}
          />
        </TableWrapper>
        <TableWrapper>
          <TableTitle>
            Wizja i innowacje
            <ButtonsWrapper>
              <VotersButton onClick={() => navigate('/finalisci/innowacje')}>Zobacz całą kategorię</VotersButton>
            </ButtonsWrapper>
          </TableTitle>
          <VotingTable
            onVote={handleUpdateVote}
            votes={votes}
            candidates={candidates.innovation}
            voter={voter}
          />
        </TableWrapper>
        <TableWrapper>
          <TableTitle>
            Działalność społeczna
            <ButtonsWrapper>
              <VotersButton onClick={() => navigate('/finalisci/dzialalnosc-spoleczna')}>Zobacz całą kategorię</VotersButton>
            </ButtonsWrapper>
          </TableTitle>
          <VotingTable
            onVote={handleUpdateVote}
            votes={votes}
            candidates={candidates.charity}
            voter={voter}
          />
        </TableWrapper>
        <div style={{ display: 'none' }}>
          {!isSaved && (
            <>
              <VotersButton onClick={handleConfirmModalOpen}>Zatwierdź formularz</VotersButton>
              <Modal
                open={confirmModalOpen}
                onClose={handleConfirmModalClose}
                title='Potwierdź wysłanie formularza'
              >
                Czy na pewno chcesz zatwierdzić swoje głosy? Pamiętaj, że po zatwierdzeniu nie będzie możliwości ich zmiany.
                <ModalButtons>
                  <VotersButton onClick={handleConfirmModalClose}>Anuluj</VotersButton>
                </ModalButtons>
              </Modal>
            </>
          )}
        </div>
      </ContentWrapper>
    )
      : (
        <div>głosowanie nieaktywne</div>
      )
}

export default Voting

const ContentWrapper = styled.div`
  width: 80%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 16px;

  ${mobileCss(`
    width: 90%;
    padding: 16px 0;
  `)};
`
const EmptyState = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  gap: 20px;
`
const VotersButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  margin: 0;
`
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 10px;
`
const Title = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 32px;
`
const HeadLine = styled.div`
  font-size: 20px;
`
const TableWrapper = styled.div`
  margin: 40px 0;
`
const TableTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;

  ${mobileCss(`
    padding: 0;
  `)};
`

const ModalButtons = styled.div`
  margin-top: 30px;
  gap: 16px;
  display: flex;
  width: 100%;
  justify-content: center;
  
`
const InlineLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
  font-weight: 700;
`
