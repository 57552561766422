import React, { useEffect, useState } from 'react'
import { isNotNilOrEmpty } from 'utils/ramda'
import { VoterTypes } from 'admin/pages/Voters'
import styled from 'styled-components'
import { mobileCss } from 'utils/theme'
import { supabase } from 'utils/supabase'
import { VotingTableRowTypes } from 'admin/components/VotingTable'

interface ResultsPresentationProps {
  voters: VoterTypes[]
}

interface CandidateScore {
  id: string
  nominee: string
  totalScore: number
}

const ResultsPresentationTable = ({ voters }: ResultsPresentationProps) => {
  const [results, setResults] = useState<{
    success: CandidateScore[]
    innovation: CandidateScore[]
    charity: CandidateScore[]
  }>({
    success: [],
    innovation: [],
    charity: []
  })

  const calculateResults = async () => {
    const response = await supabase
      .from('candidates')
      .select()
      .eq('finalist', true)

    const candidates = response.data as VotingTableRowTypes[]

    const calculateScores = (type: string) => {
      const typeCandidates = candidates.filter(c => c.type === type)
      
      return typeCandidates.map(candidate => {
        const totalScore = voters.reduce((acc, voter) => {
          const vote = voter.votes[candidate.id]
          return acc + (vote?.score ? Number(vote.score) : 0)
        }, 0)

        return {
          id: candidate.id,
          nominee: candidate.nominee,
          totalScore
        }
      })
    }

    setResults({
      success: calculateScores('success'),
      innovation: calculateScores('innovation'),
      charity: calculateScores('charity')
    })
  }

  useEffect(() => {
    // Initial fetch
    if (voters.length > 0) {
      calculateResults()
    }

    // Set up polling every second
    const interval = setInterval(() => {
      if (voters.length > 0) {
        calculateResults()
      }
    }, 1000)

    // Cleanup interval on component unmount
    return () => clearInterval(interval)
  }, [voters]) // Only re-run if voters change

  const sortCandidates = (candidates: CandidateScore[]): CandidateScore[] => {
    // Split candidates into those with points and those without
    const withPoints = candidates.filter(c => c.totalScore > 0)
    const withoutPoints = candidates.filter(c => c.totalScore === 0)

    // Sort candidates with points by score (descending)
    const sortedWithPoints = withPoints.sort((a, b) => b.totalScore - a.totalScore)

    // Sort candidates without points alphabetically
    const sortedWithoutPoints = withoutPoints.sort((a, b) => 
      a.nominee.localeCompare(b.nominee)
    )

    // Combine the arrays
    return [...sortedWithPoints, ...sortedWithoutPoints]
  }

  const renderTable = (candidates: CandidateScore[]) => {
    const sortedCandidates = sortCandidates(candidates)
    const hasAnyPoints = sortedCandidates.some(c => c.totalScore > 0)

    return (
      <Table>
        <thead>
          <tr>
            <th>Nazwa kandydata</th>
            <th>Suma punktów</th>
          </tr>
        </thead>
        <tbody>
          {sortedCandidates.map((candidate, index) => {
            const shouldBeGreen = hasAnyPoints && candidate.totalScore > 0 && index < 3
            return (
              <TableRow 
                key={candidate.id} 
                isHighlighted={shouldBeGreen}
              >
                <td>{candidate.nominee}</td>
                <td>{candidate.totalScore}</td>
              </TableRow>
            )
          })}
        </tbody>
      </Table>
    )
  }

  return isNotNilOrEmpty(voters) ? (
    <Wrapper>
      <SectionWrapper>
        <SectionTitle>SUKCES</SectionTitle>
        <TableWrapper>
          {renderTable(results.success)}
        </TableWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <SectionTitle>WIZJA I INNOWACJE</SectionTitle>
        <TableWrapper>
          {renderTable(results.innovation)}
        </TableWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <SectionTitle>DZIAŁALNOŚĆ SPOŁECZNA</SectionTitle>
        <TableWrapper>
          {renderTable(results.charity)}
        </TableWrapper>
      </SectionWrapper>
    </Wrapper>
  ) : (
    <div>Brak danych do wyświetlenia</div>
  )
}

export default ResultsPresentationTable

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

const TableWrapper = styled.div`
  overflow-x: auto;
  margin: 20px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
`

const Table = styled.table`
  width: 100%;
  max-width: 900px;
  border-spacing: 0;
  font-size: 14px;
  background: white;
  table-layout: fixed;

  th {
    padding: 12px 16px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    font-weight: 600;
    text-align: left;

    &:last-child {
      text-align: center;
      width: 120px;
    }
  }

  td {
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
    word-wrap: break-word;

    &:last-child {
      text-align: center;
      width: 120px;
    }
  }

  ${mobileCss(`
    max-width: 100%;
    
    th, td {
      padding: 8px;
    }

    th:last-child,
    td:last-child {
      width: 80px;
    }
  `)}
`

const TableRow = styled.tr<{ isHighlighted: boolean }>`
  background-color: ${props => props.isHighlighted ? '#e6ffe6' : 'white'};
`

const SectionWrapper = styled.div`
  margin: 40px 0;
`

const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-bottom: 20px;
` 