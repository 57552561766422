import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { supabase } from 'utils/supabase';
import { Button, CircularProgress } from '@mui/material';
// @ts-ignore
import logo from 'assets/prb-gold.png';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Decision = 'Tak' | 'Nie';

interface InvitationType {
  id: string;
  firstNameA: string;
  lastNameA: string;
  firstNameB: string | null;
  lastNameB: string | null;
  decision: Decision | null;
  paperInvitation: boolean;
  replied: boolean;
}

const formatAddressNames = (invitation: InvitationType): string => {
  const firstNameA = invitation.firstNameA?.trim() || '';
  const lastNameA = invitation.lastNameA?.trim() || '';
  const firstNameB = invitation.firstNameB?.trim() || '';
  const lastNameB = invitation.lastNameB?.trim() || '';
  
  const fullNameA = `${firstNameA} ${lastNameA}`.trim();
  const fullNameB = `${firstNameB} ${lastNameB}`.trim();
  
  if (!fullNameA) return '';
  // Only include 'oraz' and second name if both firstName AND lastName of person B exist
  if (!firstNameB || !lastNameB) return fullNameA;
  
  return `${fullNameA} oraz ${fullNameB}`;
};

const InvitationResponse = () => {
  const { link } = useParams();
  const [invitation, setInvitation] = useState<InvitationType | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showPaperChoice, setShowPaperChoice] = useState(false);

  useEffect(() => {
    document.title = 'Nagroda PRB: Save the Date';
    return () => {
      document.title = 'Formularz Nominacji do nagrody PRB';
    };
  }, []);

  // Detect if we're on a mobile device
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  
  useEffect(() => {
    console.log("Device type:", isMobile ? "Mobile" : "Desktop");
    console.log("Current URL:", window.location.href);
    console.log("Link parameter:", link);
    
    fetchInvitation();
  }, [link]);

  const fetchInvitation = async () => {
    try {
      const { data, error } = await supabase
        .from('invitations')
        .select('*')
        .eq('individualLink', window.location.href)
        .single();

      if (error) throw error;
      if (!data) throw new Error('Nie znaleziono zaproszenia');

      setInvitation(data);
    } catch (err) {
      console.error("Invitation fetch error:", err);
      setError('Nie znaleziono zaproszenia lub link jest nieprawidłowy');
    } finally {
      setLoading(false);
    }
  };

  const handleDecision = async (decision: Decision) => {
    if (!invitation) return;

    try {
      const { error } = await supabase
        .from('invitations')
        .update({
          decision,
          replied: true,
          followUpNeeded: false
        })
        .eq('id', invitation.id);

      if (error) throw error;

      if (decision === 'Tak') {
        setShowPaperChoice(true);
        setInvitation(prev => prev ? { ...prev, decision } : null);
      } else {
        // Show toast for 'Nie' response
        toast.success('Dziękujemy za odpowiedź!');
        await fetchInvitation();
      }
    } catch (err) {
      console.error('Error updating decision:', err);
      setError('Wystąpił błąd podczas zapisywania decyzji');
    }
  };

  const handlePaperChoice = async (wantsPaper: boolean) => {
    if (!invitation) return;

    try {
      const { error } = await supabase
        .from('invitations')
        .update({
          paperInvitation: wantsPaper
        })
        .eq('id', invitation.id);

      if (error) throw error;
      
      // Show toast after paper choice
      toast.success('Dziękujemy za odpowiedź!');
      // Hide the paper choice view
      setShowPaperChoice(false);
      // Fetch updated invitation data
      await fetchInvitation();
    } catch (err) {
      console.error('Error updating paper choice:', err);
      setError('Wystąpił błąd podczas zapisywania wyboru');
    }
  };

  if (loading) {
    return (
      <Wrapper>
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      </Wrapper>
    );
  }

  if (error) {
    return (
      <Wrapper>
        <ErrorMessage>{error}</ErrorMessage>
      </Wrapper>
    );
  }

  if (!invitation) {
    return <Navigate to="/" />;
  }

  return (
    <Wrapper>
      <ContentCard>
        <LogoWrapper>
          <Logo src={logo} alt="PRB Logo" />
        </LogoWrapper>
        <h1>Save the Date</h1>
        <h2>Sz.P. {formatAddressNames(invitation)}</h2>
        <h2><b>Czy będziesz uczestniczyć w Gali Nagrody Polskiej Rady Biznesu?</b></h2>
        <p>Wydarzenie odbędzie się w poniedziałek 26 maja 2025 roku o godz. 19:30 w Ufficio Primo w Warszawie.</p>
        <p>Potwierdzam udział w Gali:</p>
        {!showPaperChoice ? (
          <>
            <ButtonsContainer>
              <StyledButton
                variant="contained"
                selected={invitation.decision === 'Tak'}
                onClick={() => handleDecision('Tak')}
                disabled={invitation.replied && invitation.decision === 'Tak'}
              >
                Tak
              </StyledButton>
              <StyledButton
                variant="contained"
                selected={invitation.decision === 'Nie'}
                onClick={() => handleDecision('Nie')}
                disabled={invitation.replied && invitation.decision === 'Nie'}
              >
                Nie
              </StyledButton>
            </ButtonsContainer>
            {invitation.decision === 'Tak' && (
              <ResponseMessage>
                Dziękujemy za odpowiedź: <strong>{invitation.decision}</strong>
                {invitation.paperInvitation !== null && (
                  <div>
                    Wybrana forma zaproszenia:{' '}
                    <strong>
                      {invitation.paperInvitation ? 'Papierowa' : 'Elektroniczna'}
                    </strong>
                  </div>
                )}
              </ResponseMessage>
            )}
            {invitation.decision === 'Nie' && (
              <ResponseMessage>
                Dziękujemy za odpowiedź: <strong>{invitation.decision}</strong>
              </ResponseMessage>
            )}
          </>
        ) : (
          <>
            <h2>Wybieram zaproszenie:</h2>
            <ButtonsContainer>
              <StyledButton
                variant="contained"
                onClick={() => handlePaperChoice(true)}
              >
                papierowe
              </StyledButton>
              <StyledButton
                variant="contained"
                onClick={() => handlePaperChoice(false)}
              >
                elektroniczne
              </StyledButton>
            </ButtonsContainer>
          </>
        )}
      </ContentCard>
      <ToastContainer position="bottom-center" autoClose={5000} />
    </Wrapper>
  );
};

export default InvitationResponse;

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
`;

const ContentCard = styled.div`
  background: white;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  text-align: center;

  h1 {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 24px;
  }

  h2 {
    margin: 24px 0;
  }

  p {
    font-size: 18px;
    margin-bottom: 24px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap;
  margin: 24px 0;
`;

const StyledButton = styled(Button)<{ selected?: boolean }>`
  && {
    width: 250px;
    padding: 16px 24px;
    margin: 8px;
    border: 2px solid #C5A572;
    background-color: ${({ selected }) => selected ? '#C5A572' : 'white'};
    color: ${({ selected }) => selected ? 'white' : '#C5A572'};
    
    &:hover {
      background-color: ${({ selected }) => selected ? '#C5A572' : 'rgba(197, 165, 114, 0.1)'};
      border-color: #C5A572;
    }

    &:disabled {
      background-color: ${({ selected }) => selected ? '#C5A572' : 'white'};
      border-color: #C5A572;
      color: ${({ selected }) => selected ? 'white' : '#C5A572'};
      opacity: 0.7;
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error};
  text-align: center;
  font-size: 18px;
  padding: 24px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ResponseMessage = styled.div`
  margin-top: 24px;
  padding: 16px;
  background-color: #f8f8f8;
  border-radius: 4px;
  line-height: 1.6;
`;

const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 32px;
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
`; 